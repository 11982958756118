<template>
  <div>
    <!-- 养老报告详情 -->
    <table class="table">
      <caption class="text-center">
        <div v-if="table.reformType ==='ARCHIVE'">适老化改造档案</div>
        <div v-if="table.reformType ==='BASIC_INFO'">适老化改造基本信息确认</div>
        <div v-if="table.reformType ==='APPLY'">适老化改造申请</div>
        <div v-if="table.reformType ==='PERSON_ASSESS'">适老化改造老年人评估</div>
        <div v-if="table.reformType ==='REQUIRE_CONFIRM'">适老化改造需求确认</div>
        <div v-if="table.reformType ==='REQUIRE_CONSTRUCT'">适老化改造施工</div>
        <div v-if="table.reformType ==='REQUIRE_ACCEPT'">适老化改造需求验收</div>
        <div v-if="table.reformType ==='REFORM_GIVE_UP'">放弃改造承诺书</div>
      </caption>
      <tbody>
      <tr>
        <td class="td1111">
          <div class="userimg float-left">
            <img width="107" height="135" :src="table.photoInfo.url"
                 onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'">
          </div>
        </td>
        <td colspan="4">
          <div class="tableinfo">
            <div class="frow">
              <div class="fcol w400">
                <label> <span class="font14">姓</span> <span class="font14">名：</span>
                </label> <label><span class="font14"><a>{{ table.name }}</a></span></label>
              </div>
              <div class="fcol w400">
                <label><span class="font14">性</span> <span class="font14">别：</span></label>
                <label><span class="font14">{{ table.sex }}</span></label>
              </div>
              <div class="fcol ">
                <label>户籍所在地：</label>
                <label><span class="font14">{{ table.censusProvince }}{{ table.censusCity }}{{
                    table.censusArea
                  }}{{ table.censusStreet }}{{ table.censusCommunity }}{{ table.censusAddress }}</span></label>
              </div>
            </div>
            <div class="frow">
              <div class="fcol w400">
                <label><span class="font14">年</span><span class="font14">龄：</span></label>
                <label><span class="font14">{{ table.age }}</span></label>
              </div>
              <div class="fcol w400">
                <label>身份证号：</label> <label><span class="font14">{{ table.idNum }}</span></label>
              </div>
              <div class="fcol">
                <label>联系方式：</label> <label><span class="font14">{{ table.contract }}</span></label>
              </div>
            </div>
            <div class="frow">
              <div class="fcol w400">
                <label>自理能力：</label> <label><span class="font14">{{ table.selfCareAbiyName }}</span></label>
              </div>
              <div class="fcol ">
                <label><span class="font14">类</span><span class="font14">别：</span></label>
                <label><span class="font14">{{ table.personTypeNames }}</span></label>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">申请改造住宅<br/>地址：</span></td>
        <td class="text-left" colspan="2"><span class="font14">
          {{ table.usualProvince }}{{ table.usualCity }}{{
            table.usualArea
          }}{{ table.usualStreet }}{{ table.usualCommunity }}{{ table.usualAddress }}
        </span></td>
        <td class="text-right"><span class="font14">申请时间：</span></td>
        <td class="text-left">
          <span class="font14">{{ table.applyTime }}</span><br>
          <!-- <span class="font14">-</span> -->
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">审核状态：</span></td>
        <td class="text-left" colspan="4"><span
            :class="{'font14':true,'pass-name':table.auditState==='已通过','un-pass-name':table.auditState==='未通过','stay-pass-name':table.auditState!=='未通过' && table.auditState!=='已通过'}">{{
            table.auditState
          }}</span></td>
      </tr>
      <tr v-if="table.auditState==='已通过' || table.auditState==='未通过'">
        <td class="text-right"><span class="font14">审核人</span></td>
        <td class="text-left" colspan="2"><span class="font14">
          {{ table.lastAuditor }}</span></td>
        <td class="text-right"><span class="font14">审核时间：</span></td>
        <td class="text-left">
          <span class="font14">{{ table.lastAuditTime }}</span><br>
          <!-- <span class="font14">-</span> -->
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">备注：</span></td>
        <td class="text-left" colspan="4"><span class="font14">{{ table.remark }}</span></td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import UploadImg from '@/components/UploadImg.vue'

export default {
  components: {},
  data() {
    return {
      table: {
        photoInfo: { url: '' }
      }
    }
  },
  created() {
    console.log(this.$route)
    this.queryDetail()
  },
  methods: {
    ...mapActions([
      'elderlyAuditDetail'
    ]),
    queryDetail() {
      this.elderlyAuditDetail(this.$route.query.id).then(res => {
        if (res.code === 200) {
          if (res.data.photoInfo === null) {
            res.data.photoInfo = { url: '' }
          }
          this.table = {
            ...res.data
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
.servertable {
  position: relative;
}

.backwhite {
  background-color: #fff;
  width: 98%;
  padding: 40px 0 0 50px;
  float: left;
}

.table {
  background-color: transparent;
  max-width: 100%;
  margin-bottom: 20px;
  width: 98%;
  border-spacing: 0;
  border-collapse: collapse;

}

/* :deep .el-descriptions__header{
  display: flex;
  justify-content: center !important;

  font-size: 24px;
} */
/* :deep .el-descriptions__header .el-descriptions__title{
    color: #488fe1;
    font-size: 24px;
    padding: 0;
    font-weight: 400;
}
.img{
  width: 150px;
  height: 150px;
} */
.table tr .td1111 {
  width: 170px;
  padding-left: 24px;
}

.td1111 {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #333;
  border-spacing: 0;
  border-collapse: collapse;
  margin: 0;
  box-sizing: border-box;
  font-family: "Microsoft YaHei", "微软雅黑";
  list-style: none;
  outline: none;
  text-decoration: none;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: middle;
  font-size: 14px;
  height: 50px;
  border: 1px solid #e2e5e7;
  width: 170px;
  padding-left: 24px;

}

.float-left {
  float: left;
}

.tableinfo {
  margin-left: 1em;
}

.frow {
  width: 100%;
  display: flex;
  align-items: center;
  float: left;
  display: -moz-box;
  /* display: -webkit-box; */
  display: box;
  -moz-box-align: center;
  -webkit-box-align: center;
  display: -ms-flexbox;
  -ms-flex-align: center;
}

.tableinfo {
  margin-left: 1em;
}

.frow .fcol {
  float: left;
  /* height: 60px; */
  line-height: 60px;
  position: relative;
}

.w200 {
  width: 200px;
}

.w400 {
  width: 400px;
}

.font14 {
  font-size: 14px;
}

.table tr td {
  vertical-align: middle;
  font-size: 14px;
  height: 50px;
  border: 1px solid #e2e5e7;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: middle;
  /* border-top: 1px solid #ddd; */
}

.table caption {
  color: #488fe1;
  font-size: 24px;
  padding: 0;
  margin-bottom: 16px;
}

.table tr td {
  vertical-align: middle;
  font-size: 14px;
  height: 50px;
  border: 1px solid #e2e5e7;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

table tr td .bz {
  color: #777676;
  line-height: 31px;
  padding-left: 17px;
  padding-right: 50px;
}

.table tr td .bz h2 {
  font-size: 18px;
  margin: 5px 0px;
  line-height: 1em;
  font-family: inherit;
  font-weight: 500;
  color: inherit;
}

.table tr td .bz p {
  padding-left: 11px;
}

.table .btngroup {
  margin: 15px 0;
}

.table .btngroup .blue {
  background-color: #488fe1;
}

.table .btngroup a {
  color: white;
  font-size: 16px;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 6px;
  margin: 0 16px;
}

.text-center {
  text-align: center;
}

.table a, .servertable .table a:visited, .servertable .table a:active, .servertable .table a:link, .servertable .table a:hover {
  color: #5093E2;
  font-size: 14px;
}

.userimg img {
  border: 1px solid #e7e7e7;
  margin-bottom: 10px;
  margin-left: 5px;
}

.servertable .table .btngroup .blue:hover {
  background-color: #3183E3;
}

.servertable .table .btngroup a:hover {
  color: white;
  font-size: 16px;
}

.servertable .table tr td .bz header {
  vertical-align: middle;
  font-size: 14px;
  height: auto;
  border: none;
  padding: 0;
}

.pass-name {
  color: #2EBC21;
}

.un-pass-name {
  color: #E42C28;
}

.stay-pass-name {
  color: #F09932;
}
</style>
